@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-base bg-primary-light;
  }
  h6 {
    @apply text-sm text-gray-400;
  }

  .input {
    @apply w-full h-full px-1 ring-0 outline-none text-sm font-normal;
  }

  .agreement table td {
    @apply py-2 pr-2 text-start;
  }

  .agreement p {
    @apply mb-4;
  }
  .agreement li {
    @apply mb-5;
  }

  .agreement ul li:last-child, .agreement li p:only-child {
    @apply mb-0
  }

  .agreement ol {
    @apply list-inside;
  }
  .agreement li {
    @apply inline-flex;
  }
}

.agreement ol {
  counter-reset: item;
}
.agreement li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
 @apply mr-1.5 md:mr-3
}

.agreement p,
.agreement li {
  font-size: 13px;
  line-height: 15.73px;
  font-weight: 400;
}

.agreement ul {
  counter-reset: subitem;
}

.agreement ul li:before {
  counter-increment: subitem;
  content: counter(item) "." counter(subitem) ". ";
  @apply mr-1.5 md:mr-3
}

progress {
  /* style rules */
  border-radius: 20px;
  height: 10px;
}
progress::-webkit-progress-bar {
  /* style rules */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
progress::-webkit-progress-value {
  /* style rules */
  background-color: #1eb12d;
  border-radius: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
}

/* Spinner */
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

input[type="number"].input-number-no-control::-webkit-inner-spin-button,
input[type="number"].input-number-no-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].input-number-no-control::-webkit-inner-spin-button,
input[type="number"].input-number-no-control::-webkit-outer-spin-button {
  opacity: 1;
}

.plain-modal {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}

/* width */
.scroll-section::-webkit-scrollbar {
  width: 10px;
  margin-left: 8px;
}

/* Track */
.scroll-section::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
.scroll-section::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
.scroll-section::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
